/** @jsx jsx */
import { useEffect, useState } from "react"
import YouTube from "react-youtube"
import { jsx } from "theme-ui"
import Layout from "../components/layout"
import axios from "axios"
import "./ytchannel.css"

const YoutubeChannel = () => {
  const [videos, setVideos] = useState([])
  const [chosen, setChosen] = useState("")
  useEffect(() => {
    axios
      .get(
        "https://youtube.googleapis.com/youtube/v3/channels?part=contentDetails&forUsername=ShirinSaluja&key=AIzaSyCgeBTQS2W8uUB0AnAnRCl2-9N9pBoSYfc",
        {
          headers: {
            Accept: `application/json`,
          },
        }
      )
      .then(resp => {
        console.log(resp.data)
        axios
          .get(`https://youtube.googleapis.com/youtube/v3/playlistItems?`, {
            params: {
              part: "snippet",
              maxResults: 20,
              playlistId:
                resp.data.items[0].contentDetails.relatedPlaylists.uploads,
              key: `AIzaSyCgeBTQS2W8uUB0AnAnRCl2-9N9pBoSYfc`,
            },
          })
          .then(resp => {
            console.log(resp.data)
            setVideos(resp.data.items)
          })
          .catch(err => {
            console.error(err)
          })
        setVideos(resp.data)
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  const opts = {
    // height: "390",
    width: "320",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  }
  const _onReady = event => {
    // access to player in all event handlers via event.target
    event.target.playVideo()
  }
  return (
    <Layout className="page">
      <div className="ytview" sx={{
        // display: "flex", 
        // flexWrap: "wrap",
        }}>
      {videos.map &&
        videos.map(video => {
          const component =
            chosen === video.id ? (
              <YouTube
                containerClassName="youtubeContainer"
                sx={{ bg: "siteColor" }}
                videoId={video.snippet.resourceId.videoId}
                opts={opts}
                onReady={_onReady}
              />
            ) : (
              
              <div sx={{
                textAlign: 'center'
              }}>
                <img
                  sx={{
                    margin: "10px",
                    boxShadow: "3px 3px 3px black",
                    "&:hover": {
                      filter: "blur(8px)",
                    },
                    "&:hover ~ p": {
                      marginLeft: -320,
                      display: "inline",
                    },
                    "&:hover ~ button": {
                      display: "inline",
                    },
                  }}
                  src={video.snippet.thumbnails.medium.url}
                  alt="video"
                  onClick={() => setChosen(video.id)}
                />
                <p
                  sx={{
                    position: "absolute",
                    color: "black",
                    width: "240px",
                    margin: "13px",
                    display: "none",
                    pointerEvents: "none",
                    fontSize: "10pt",
                    margin: "10px",
                  }}
                >
                  {/* <Button
                    sx={{
                      // pointerEvents: "none",
                      "&:hover ~ img": { filter: "blur(8px)" },
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => setChosen(video.id)}
                  >
                    play
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      // pointerEvents: "none",
                      "&:hover ~ img": { filter: "blur(8px)" },
                    }}
                  >
                    Read more
                  </Button> */}
                  {/* {video.snippet.description.title} */}
                  {video.snippet.description.substring(0, 300)}
                </p>
              </div>
              
              
            )

          return (
            <div key={video.id} sx={{ display: "inline-block" }}>
              {component}
              <div sx={{marginLeft:"20px",textAlign:"center", maxWidth: "320px", height: "40px"}}>
                {video.snippet.title}
              </div>
            </div>
          )
        })}
        </div>
    </Layout>
  )
}
export default YoutubeChannel
